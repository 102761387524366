@import 'portal-constants';

// fullscreen dialog
.containment-context .mdc-dialog.cdk-dialog-container.mdc-dialog--open .mdc-dialog__surface {
  box-shadow: none;
  border-radius: 0;
}

.containment-context.cdk-overlay-pane {
  max-width: unset !important;
}
