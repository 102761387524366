/* You can add global styles to this file, and also import other style files */
@import 'portal-components';
@import 'portal-theme';
@import 'portal-material-theme';
@import 'dialog-styles';

html,
body {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

html {
  // define how much 1rem should be
  font-size: 10px;
}

body {
  margin: 0; // remove default body styles
}

* {
  font-family: $font-family;
}

.containment-context {
  container-type: inline-size;
}

// added this because the injection token (setting the disableTooltipInteractivity to true) is not working if the tooltip trigger is inside an overlay
.mat-mdc-tooltip-panel {
  pointer-events: none;
}

.mat-mdc-menu-panel {
  box-shadow:
    0 0.2rem 0.4rem -0.1rem rgba(38 50 56 / 0.2),
    0 0.4rem 0.5rem 0 rgba(38 50 56 / 0.14),
    0 0.1rem 1rem 0 rgba(38 50 56 / 0.12);
}

// fix for doubled tooltips in safari
[spaceAwareTooltip]::after {
  content: '';
  display: block;
}
