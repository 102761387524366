/* stylelint-disable */
@use '@angular/material' as mat;

// we do not have a primary or accent color, so currently we just use prebuilt one by angular material
$primary: mat.m2-define-palette(mat.$m2-indigo-palette, 500);
$accent: mat.m2-define-palette(mat.$m2-pink-palette, A200, A100, A400);

$celum-typography: mat.m2-define-typography-config(
  $font-family: '"Open Sans"',
  $headline-4: mat.m2-define-typography-level(40px, 42px, 300),
  $headline-5: mat.m2-define-typography-level(24px, 32px, 300),
  $headline-6: mat.m2-define-typography-level(20px, 28px, 400),
  $subtitle-1: mat.m2-define-typography-level(16px, 28px, 400),
  $body-2: mat.m2-define-typography-level(13px, 18px, 400),
  $body-1: mat.m2-define-typography-level(13px, 18px, 400),
  $caption: mat.m2-define-typography-level(11px, 11px, 400),
  $button: mat.m2-define-typography-level(12px, 40px, 500)
);

$por-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $primary,
      accent: $accent
    ),
    typography: $celum-typography,
    density: 0
  )
);

@include mat.core-theme($por-theme);

// List of all material components that we use for portals, needs to be up2date whenever a new one is used in order to get correct styles.
@include mat.checkbox-theme($por-theme);
@include mat.datepicker-theme($por-theme);
@include mat.dialog-theme($por-theme);
@include mat.slider-theme($por-theme);
@include mat.slide-toggle-theme($por-theme);
@include mat.tooltip-theme($por-theme);
