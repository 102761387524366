@import 'portal-constants';
@import 'shared-styles';

.por-dialog {
  --mdc-dialog-container-shape: 0.8rem;

  max-height: calc(100dvh - 8rem);
  display: flex;
  flex-direction: column;

  @container #{$media-query-mobile} {
    &--mobile-full-screen {
      min-width: 100vw;
      height: 100dvh;
      max-height: 100dvh;

      .mdc-dialog__surface {
        border-radius: 0;
      }
    }
  }

  &_header {
    display: flex;
    flex-direction: column;

    font-size: 2rem;
    line-height: 1.4;
    color: $color-gray-5;
    font-weight: 600;
    padding: 2.8rem 4rem;
  }

  &_separator {
    width: 100%;
    height: 1px;
    border-bottom: 0.1rem solid $color-gray-2;
    margin-bottom: 4rem;
  }

  &_content {
    line-height: 1.54;
    padding: 0.1rem 4rem;
    color: $color-gray-5;
    font-size: 1.3rem;
    white-space: pre-wrap;
    overflow-wrap: break-word;
    overflow: auto;
    box-sizing: border-box;
    flex: 1;
  }

  &_footer {
    padding: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
