@import 'portal-constants';
@import 'shared-styles';

mat-slider.mat-mdc-slider {
  --mdc-slider-handle-color: #{$color-gray-5};
  --mdc-slider-focus-handle-color: #{$color-gray-5};
  --mdc-slider-with-overlap-handle-outline-color: #{$color-gray-5};
  --mdc-slider-with-overlap-handle-outline-width: 0;
  --mdc-slider-active-track-color: #{$color-gray-5};

  &:hover {
    --mdc-slider-handle-color: #{$color-gray-5};
  }

  height: 2.4rem;

  .mdc-slider__track {
    height: 0;

    .mdc-slider__track--active,
    .mdc-slider__track--inactive {
      height: 0.1rem;
    }

    .mdc-slider__track--inactive {
      opacity: 1;
      background-color: $color-gray-3;
    }

    .mdc-slider__track--active {
      background-color: unset;
      height: 0.3rem;

      .mdc-slider__track--active_fill {
        border-width: 0.3rem;
      }
    }
  }

  .mdc-slider__thumb,
  .mat-mdc-slider-visual-thumb,
  .mdc-slider__thumb--focused {
    height: 2.4rem;

    .mdc-slider__thumb-knob {
      width: 1.4rem;
      height: 1.4rem;
      border-width: 0;
      box-shadow: unset;
    }

    .mat-ripple {
      display: none;
    }
  }

  .mdc-slider__thumb--with-indicator .mdc-slider__thumb-knob:before {
    @include applyLayerStyles($color-global-foreground, 0.08);
  }

  &.mdc-slider--disabled {
    .mdc-slider__input {
      cursor: default;
    }

    .mdc-slider__thumb-knob {
      background-color: $color-gray-5;
    }
  }
}
