/* stylelint-disable */
@use 'sass:list';

// THEMING EXPLANATION
// ===========================
// COLORS
// We define the colors in HSL(A) format, as it is very flexible in changing individual parts of the color.
// For each color we separately define a CSS variable for each part of the HSL(A) format (hue, saturation, lightness).
// We then combine these into one CSS variable for easier usability.
// Example individual parts: --color-primary-h, --color-primary-s, --color-primary-l
// Example combined: --color-primary: hsl(var(--color-primary-h), var(--color-primary-s), var(--color-primary-l))
//
// COLOR THEMING
// For each color that we expose through our design theme, we will have the following sets of variables:
// - celum default color variables (e.g. --clm-por-col-primary)
// - design theme color variables (e.g. --dt-col-primary) - these will be set dynamically by javascript through the PortalThemeService
// - a combined variable that uses the design theme color if available, otherwise falls back to the celum default color (e.g. --color-primary)
// Only the combined variable is exposed via constants in portal-constants and used throughout the app.
//
// TYPOGRAPHY
// For each typography we define a set of CSS variables for each individual property (font-family, font-size, ...).
// Each of these properties consists of a set of variables similar to the color theming
// - celum default typography variables (e.g. --clm-por-typ-heading-1-family)
// - design theme typography variables (e.g. --dt-typ-heading-1-family) - these will be set dynamically by javascript through the PortalThemeService
// - a combined variable that uses the design theme typography if available, otherwise falls back to the celum default typography (e.g. --typ-heading-1-family)
// Additionally, we create a css class that combines all properties which can be applied to elements that should inherit the typography of the design theme.

// prettier-ignore
$colorsHsl: [
  [ 'white', 0, 100, 100],
  [ 'black', 0, 0, 0],
  [ 'error', 0, 100, 62],
  [ 'warning', 28.29, 100, 51.76],
  [ 'success', 140, 54, 47],
  [ 'info', 211, 39, 23]
];

// prettier-ignore
$themeColorsHsl: [
  [ 'primary', 189.85, 99.01, 39.8],
  [ 'contrast', 0, 0, 100],
  [ 'gray-1', 210, 36.4, 95.7],
  [ 'gray-2', 212, 33.3, 88.8],
  [ 'gray-3', 210, 31.4, 80],
  [ 'gray-4', 211, 27.2, 70.4],
  [ 'gray-5', 209, 28.4, 39.4],
  [ 'gray-6', 211, 39.5, 23.3],
  [ 'error', 0, 100, 62],
  [ 'warning', 28, 100, 52],
];

// prettier-ignore
$typographies: [
  // name, family, size, height, weight, decoration, style, transform, color
  ['heading-1', 'var(--font-family)', 3.2rem, 1.2, bold, none, none, uppercase, 'var(--color-gray-5)'],
  ['heading-2', 'var(--font-family)', 2.4rem, 1.2, bold, none, none, none, 'var(--color-gray-5)'],
  ['heading-3', 'var(--font-family)', 2rem, 1.2, normal, none, none, none, 'var(--color-gray-5)'],
  ['body', 'var(--font-family)', 1.3rem, 1.2, normal, none, none, none, 'var(--color-gray-5)'],
  ['quote', 'var(--font-family)', 2.4rem, 1.5, normal, none, italic, none, 'var(--color-primary)']
];

// mapping of a theme name to its corresponding css attribute
// prettier-ignore
$typ-attribute-map: [
    ['family', 'font-family'], ['size', 'font-size'], ['height', 'line-height'], ['weight', 'font-weight'],
    ['decoration', 'text-decoration'], ['style', 'font-style'], ['transform', 'text-transform'], ['color', 'color']
  ];

:host,
.portal-theme {
  @each $color-parts in $colorsHsl {
    $color-name: color-#{list.nth($color-parts, 1)};
    --#{$color-name}-h: #{list.nth($color-parts, 2)};
    --#{$color-name}-s: #{list.nth($color-parts, 3)}%;
    --#{$color-name}-l: #{list.nth($color-parts, 4)}%;
    --#{$color-name}: hsl(var(--#{$color-name}-h), var(--#{$color-name}-s), var(--#{$color-name}-l));
  }

  @each $color-parts in $themeColorsHsl {
    $color-name: #{list.nth($color-parts, 1)}; // primary, gray-1, ...
    $clm-color: clm-por-col-#{$color-name}; // clm-por-col-primary, clm-por-col-gray-1, ...
    $dt-color: dt-col-#{$color-name}; // dt-col-primary, dt-col-gray-1, ...

    // celum defaults
    --#{$clm-color}-h: #{list.nth($color-parts, 2)};
    --#{$clm-color}-s: #{list.nth($color-parts, 3)}%;
    --#{$clm-color}-l: #{list.nth($color-parts, 4)}%;
    --#{$clm-color}: hsl(var(--#{$clm-color}-h), var(--#{$clm-color}-s), var(--#{$clm-color}-l));

    // theme colors or celum defaults as fallback
    --color-#{$color-name}-h: var(--#{$dt-color}-h, var(--#{$clm-color}-h));
    --color-#{$color-name}-s: var(--#{$dt-color}-s, var(--#{$clm-color}-s));
    --color-#{$color-name}-l: var(--#{$dt-color}-l, var(--#{$clm-color}-l));
    --color-#{$color-name}: hsl(var(--color-#{$color-name}-h), var(--color-#{$color-name}-s), var(--color-#{$color-name}-l));
  }

  --clm-por-typ-main-family: 'Open Sans', sans-serif;

  @each $typography in $typographies {
    $typ-name: #{list.nth($typography, 1)}; // heading-1, body, ...
    $clm-typ: clm-por-typ-#{$typ-name}; // clm-por-typ-heading-1, clm-por-typ-body, ...
    $dt-typ: dt-typ-#{$typ-name}; // dt-typ-heading-1 dt-typ-body, ...

    @each $attr-map in $typ-attribute-map {
      $attr: #{list.nth($attr-map, 1)}; // family, size, ...
      --#{$clm-typ}-#{$attr}: #{list.nth($typography, index($typ-attribute-map, $attr-map) + 1)};
      --typ-#{$typ-name}-#{$attr}: var(--#{$dt-typ}-#{$attr}, var(--#{$clm-typ}-#{$attr}));
    }

    .typ-#{$typ-name} {
      @each $attr-map in $typ-attribute-map {
        $attr: #{list.nth($attr-map, 1)}; // family, size, ...
        $cssAttr: #{list.nth($attr-map, 2)}; // font-family, font-size, ...
        #{$cssAttr}: var(--typ-#{$typ-name}-#{$attr});
      }
    }
  }

  // CELUM font definitions (used for our own UI, only relation to theme is the main font-family)
  // --portal-main-family is used as the font for portals without a design theme. It is set in the portal.component.
  --font-family: var(--dt-typ-main-family, var(--portal-main-family, var(--clm-por-typ-main-family)));
  --font-display: normal 700 2.8rem/3.6rem var(--font-family);
  --font-headline: normal 700 2.4rem/3.2rem var(--font-family);
  --font-title: normal 600 2rem/2.8rem var(--font-family);
  --font-subheader: normal 600 1.6rem/2.4rem var(--font-family);
  --font-body: normal 400 1.3rem/2rem var(--font-family);
  --font-caption: normal 400 1.1rem/1.6rem var(--font-family);
  --font-button: normal 400 1.2rem/2.2rem var(--font-family);

  --clm-virtual-list-item-padding-right: 1.2rem;
  --clm-native-scrollbar-track-hover-color: var(--color-gray-2);
  --clm-native-scrollbar-thumb-color: var(--color-gray-4);
  --clm-native-scrollbar-thumb-hover-color: var(--color-gray-5);
}
